import React from 'react'
import { completed_records } from '../../../data/data'

const CompletedRecords = () => {
  return (
    <>
      {completed_records.map((record, k) => (
        <div key={k}>{record.desc}</div>
      ))}
    </>
  )
}

export default CompletedRecords
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicRequest } from "../../app/connect";


export const fetchCapcha = createAsyncThunk('/auth/capcha/', async (_, { rejectWithValue }) => {
  try {
      const { data } = await publicRequest.get("/auth/re-capcha", );
      return data;
  } catch (error) {
      return rejectWithValue(error.response.data)
  }
})


const initialState = {
  capcha: [],
  loading: false,
  error: null,
  isSuccess: false,
};

const capchaSlice = createSlice({
  name: "capcha",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchCapcha.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCapcha.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.isSuccess = true;
        state.capcha = action.payload;
      })

      .addCase(fetchCapcha.rejected, (state, action) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })

  },
});

export default capchaSlice.reducer;
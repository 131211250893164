import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../app/connect";




export const WithdrawCredit = createAsyncThunk('/auth/user/withdraw', async ({...credentials}, { rejectWithValue }) => {
  try {
      const { data } = await userRequest.post("/user/withdraw", credentials);
      return data;
  } catch (error) {
      return rejectWithValue(error.response.data)
  }
})


const initialState = {
  withdraw: [],
  loading: false,
  error: null,
  isSuccess: false,
};

const withdrawSlice = createSlice({
  name: "withdraw",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(WithdrawCredit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(WithdrawCredit.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.isSuccess = true;
        state.withdraw = action.payload;
      })

      .addCase(WithdrawCredit.rejected, (state, action) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })

  },
});

export default withdrawSlice.reducer;
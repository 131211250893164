import { Icons } from "../constant/Icons";
import { Images } from "../constant/Images";


export const nav_list = [
    {
        icon: <Icons.HomeIcon />,
        title: "Home",
        path: "/dashboard/user/welcome"
    },

    {
        icon: <Icons.EventIcon />,
        title: "Event",
        path: "/dashboard/user/event"
    },

    {
        icon: <Icons.BookingIcon />,
        title: "Booking",
        path: "/dashboard/user/booking"
    },

    {
        icon: <Icons.RecordIcon />,
        title: "Record",
        path: "/dashboard/user/record"
    },

    {
        icon: <Icons.UserIcon />,
        title: "Account",
        path: "/dashboard/user/account"
    },
]



export const slideImg = [
    {
        img: Images.Splash2
    },

    {
        img: Images.Splash1
    },
]



 const bookingSummary = (record) => [


    {
        title: "USDT Balance",
        amount: `$ ${record?.wallet[1]?.wallet_balance}`
    },

    {
        title: "FIAT Balance",
        amount: `$ ${record?.wallet[0]?.wallet_balance}`
    },


    {
        title: "Today Earning",
        amount: `$ ${record?.sub_balance?.today_earning}`
    },


    {
        title: "Profit",
        amount: `${record?.task?.profit ?? 0}`
    },

        
    {
        title: "On-Hold",
        amount: `$ ${record?.sub_balance?.holding_balance}`
    },

    {
        title: "Total Set",
        amount: `${record?.task?.total_set}`
    },

 
    {
        title: "Number of Ratings",
        amount: `${record?.task?.task_done}`
    },



    {
        title: "Level",
        amount: `${record?.level ?? 0}`
    },
]


export {bookingSummary}



export const bookingTaskDesc = [
    {
        desc: `One mobile phone number can only be registered for one
        account. To prevent malicious use or a series of illegal acts,
        and entrust the platform to set the corresponding progress
        rules. The system will automatically match orders for the agent
        members when they are In doing the ratings. After completing the
        rating every day, the amount can be withdrawn to the pre-set or
        bound bank account. The platform top-up only allows the owner of
        the account to transfer money, other people or anonymous 's
        transfers will affect the security of the account.`
    },

    {
        desc: `Each account needs to complete all the rating daily.`
    },

    {
        desc: `All rating are
        randomly distributed by the system, so it is impossible to
        change, cancel or skip after receiving the airfare.`
    },

    {
        desc: `Each order
        is offered by different merchants, so the profit is naturally
        different. So, every time you need to get the bank account from
        customer service before you make the Deposit. You need to
        reconfirm the bank account with customer service.`
    },

    {
        desc: `If you do
        not make the Deposit after 30 minutes, you will need to
        reconfirm the account with customer service. To prevent
        unnecessary troubles for the ongoing transaction.`
    },

    {
        desc: `The platform
        will not take responsibility if you make the transfer to the
        wrong or expired time validation bank account`
    },

    {
        desc: `Minimum deposit
        amount is $100 Maximum withdrawal amount is 10000 If
        you withdraw more than 10000  you will be subject a
        handling fee (Note: For further clarification, please contact
        our online customer service) Note: Please do not use the same
        person's bank card information to register this platform account
        repeatedly to avoid data errors.`
    },

    {
        desc: `Once the bank card has been
        bound to a single account and it can't be changed anymore unless
        the bank account has some problem that can't receive a
        withdrawal. Members are not allowed to change their passwords
        for accounts with more than 10000 dollar of funds, this is to protect
        the safety of members' funds.`
    }
]



export const tab_list = [
    {
        label: "All",
        value: "all"
    },

    {
        label: "Processing",
        value: "processing"
    },

    {
        label: "Completed",
        value: "completed"
    },
]


export const all_records = [
    {
        desc: "All Records",
    },
]


export const processing_records = [
    {
        desc: "Processing records"
    },
]


export const completed_records = [
    {
        desc: "Completed records"
    },
]
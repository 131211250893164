import React from "react";
import { PrivateLayout } from "../../../common";
import { Icons } from "../../../constant/Icons";
import { Alert, Button, Input, Typography } from "antd";
import Marquee from "react-fast-marquee";
import { Images } from "../../../constant/Images";

const Home = () => {
  return (
    <>
      <PrivateLayout>
        <section className="overflow-y-scroll">
          <div className="scrolling__text__container">
            <Alert
              banner
              icon={<Icons.SpeakerIcon />}
              message={
                <Marquee pauseOnHover gradient={false}>
                  I can be a React component, multiple React components, or just
                  some text.
                </Marquee>
              }
            />
          </div>

        <div className="traveller__container">
            <div className="traveller__departure">
              <p>From</p>
                <Icons.TransitIcon color="#fff" />
              <p>To</p>
            </div>
        </div>

        <div className="traveller__details__container">
              <section>
                <Typography>Departure</Typography>
                <Input prefix={<Icons.EventIcon />} placeholder="departure" type="date" />
              </section>

              <div className="traveller__details__container__2">
              <section>
                <Typography>Adults</Typography>
                <Input prefix={<Icons.EventIcon />} placeholder="1" type="text" />
              </section>

              <section>
                <Typography>Children</Typography>
                <Input prefix={<Icons.EventIcon />} placeholder="0" type="text" />
              </section>

              <section>
                <Typography>Class</Typography>
                <Input prefix={<Icons.EventIcon />} placeholder="Economy" type="text" />
              </section>
              </div>

              <Button type="primary" size="large" shape="round">Go to Book</Button>
        </div>

        <div className="Home__destination">
              <p>Popular Destination</p>
              <section className="home__destination__image">
                <img src={Images.Location} alt=""/>
                <img src={Images.Location} alt=""/>
                <img src={Images.Location} alt=""/>
              </section>
        </div>



        </section>
      </PrivateLayout>
    </>
  );
};

export default Home;

import React from 'react'
import { DashboardAccountLevel } from '../../components'

const AccountLevel = () => {
  return (
    <div>
        <DashboardAccountLevel />
    </div>
  )
}

export default AccountLevel
import { Button } from "antd";
import React from "react";

const DoTask = ({ close, mgs, okClose }) => {

  return (
    <div id="do__task">
       <center>
       <h2>Notice !!!</h2>
        <p>{mgs?.message}</p>

        {mgs?.message === "Ticket booking done successful." || "Ticket booking set completed successsful, proceed to withdrawal."? <Button type="primary" size="large" onClick={okClose}>Okay</Button> :  <Button type="primary" size="large" onClick={close}>Top Up</Button>}

       
       </center>
    </div>
  );
};

export default DoTask;

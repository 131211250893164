import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Account, AccountBindWallet, AccountDetails, AccountLevel, Booking, Events, ForgotUserPassword, Home, LoginUser, Record, SignUpUser } from './pages';
import { paths } from './routes/routes';
import { ProtectedRoute } from "./app/protectedRoute";
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

const App = () => {
  const { user } = useSelector(state => state.auth);
  
  return (
    <div>
      <Router>
        <Routes>
          <Route path={paths.home} element={<LoginUser />} />
          <Route path={paths.register} element={<SignUpUser />} />
          <Route path={paths.forgotPassword} element={<ForgotUserPassword />} />
          <Route path={paths.notMatch} element={<Navigate to={paths.home} />} />
          <Route element={<ProtectedRoute user={user} />}>
            <Route path={paths.dashboardOverview} element={<Home />} />
            <Route path={paths.dashboardEvent} element={<Events />} />
            <Route path={paths.dashboardBooking} element={<Booking />} />
            <Route path={paths.dashboardRecord} element={<Record />} />
            <Route path={paths.dashboardAccount} element={<Account />} />
            <Route path={paths.dashboardMembership} element={<AccountLevel />} />
            <Route path={paths.dashboardBindWallet} element={<AccountBindWallet />} />
            <Route path={paths.dashboardAccountDetails} element={<AccountDetails />} />
            <Route path={paths.notMatch} element={<Navigate to={paths.dashboardOverview} />} />
          </Route>
        </Routes>
      </Router>

      <ToastContainer position="top-center" />
    </div>
  )
}

export default App
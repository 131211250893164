import { Button, Input, Select, Upload } from "antd";
import React, { useState } from "react";
import { options, select_amt } from "../../../data/account.data";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { FundWallet } from "../../../services/application/deposit.Slice";
import { Icons } from "../../../constant/Icons";

const DepositAccount = ({ close }) => {
  const [selectedAmount, setSelectedAmount] = useState("0");
  const dispatch = useDispatch();
  const [ loading, setLoading ] = useState(false)
  const { Dragger } = Upload;

  const handleDeposit = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("amount", values.amount);
      formData.append("currency_type", values.currency_type);
      formData.append("image", values.image);
      const res = await dispatch(FundWallet(formData)).unwrap();
      setLoading(false);
      toast.success(res.message)
      close()
    } catch (err) {
      setLoading(false);
      toast.error(err?.data?.message || err.message)
    } finally {
      setLoading(false);
    }
  }


  const formik = useFormik({
    initialValues: {
      amount: "",
      currency_type: "",
      image: null
    },
    onSubmit: handleDeposit,
    validateOnMount: true,
  })
  

  const handleAmountSelection = (amt) => {
    setSelectedAmount(amt);
    formik.setFieldValue("amount", parseFloat(amt));
  };


  return (
   <form onSubmit={formik.handleSubmit}>
       <div className="account__deposit">

      <section className="account__select">
        <span className="desc">Select a Deposit Currency</span>
        <Select 
          defaultValue="Select Payment Method"
          name="currency_type"
          value={formik.values.currency_type} 
          onChange={(value) => formik.setFieldValue("currency_type", value)}
          onSelect={formik.handleChange}
          options={options} 
          size="large"
        />
      </section>

      <section className="account__select">
        <span className="desc">Deposit Amount</span>
        <Input
          size="large"
          placeholder="amount"
          name="amount" 
          value={selectedAmount && formik.values.amount}
          onChange={formik.handleChange} 
        />
      </section>



      <section className="account__select overflow-hidden">
      <Dragger
          accept=".png, .jpg, .jpeg"
          beforeUpload={(image) => {
            formik.setFieldValue("image", image);
            return false;
          }}
          onChange={(info) => {
            if (info?.image?.status === "uploading") {
              setLoading(true);
            } else if (
              info?.image?.status === "done" ||
              info?.image?.status === "error"
            ) {
              setLoading(false);
            }
          }}
        >
          {formik.values.image ? (
            <div >
                <Icons.ReceiptIcon size={30} />
              <p >
                {formik.values.image.name}
              </p>
              <p >Change File</p>
            </div>
          ) : (
            <>
              <p className="">
                <Icons.UploadIcon size={30} />
              </p>
              <p >
                Select receipt to upload
              </p>
            </>
          )}
        </Dragger>

        <p className="error pt-2">
          {formik.touched.image && formik.errors.image && formik.errors.image}
        </p>

      </section>



      <section className="account__select__amt">
        {select_amt.map((amt, index) => (
          <span
            key={index}
            className={`selected__amt ${
              selectedAmount === amt.amt ? "selected__amt__clicked" : ""
            }`}
            value={amt.amt}
            onClick={() => handleAmountSelection(amt.amt)}
          >
            {amt.amt}
          </span>
        ))}
      </section>

      <Button size="large" type="primary" htmlType="submit" disabled={!formik.isValid || loading} loading={loading} >Deposit Now</Button>
    </div>
   </form>
  );
};

export default DepositAccount;

import { Button, Input, Typography, message } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetUserPassword } from "../../services/auth/reset_password.Slice";
import { paths } from "../../routes/routes";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ isLoading, setIsLoading ] = useState(false);



  const handlePasswordReset = async (values) => {
      setIsLoading(true);
      try {
        const res = await dispatch(resetUserPassword({...values})).unwrap();
        setIsLoading(false);
        message.success(res.message);
        navigate(paths.home);
      } catch (err) {
        setIsLoading(false);
        message.error(err.message)
      } finally {
        setIsLoading(false);
      }
  }


  const formik = useFormik({
    initialValues: {
      phone_number: "",
      new_password: "",
    },
    onSubmit: handlePasswordReset,
  })

  return (
    <div className="login__wrapper">
      <div className="login__content">
        <div className="login__inner__wrapper">
          <form onSubmit={formik.handleSubmit}>
          <Typography className="top__right">English</Typography>
         
          <Input 
          placeholder="Phone Number" 
          className="input__londons mb-3"
          name="phone_number"
          value={formik.values.phone_number}
          onChange={formik.handleChange} 
          />

          {/* <Input
            placeholder="Verification Code"
            className="input__londons my-3"
            suffix={<div className="resend__code">Send Code</div>}
          /> */}
          <Input.Password
            placeholder="password"
            className="input__londons mb-3"
            name="new_password"
            value={formik.values.new_password}
            onChange={formik.handleChange} 
          />

          <Button type="primary" size="large" shape="round" htmlType="submit" loading={isLoading}>
            Reset
          </Button>
          </form>
          <Typography className="create__acc">
            Remembered Password? <Link to="/">Login</Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React, { useState, useEffect } from "react";
import { PrivateLayout } from "../../../common";
import { slideImg } from "../../../data/data";

const Event = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const changeImage = () => {
      setCurrentImageIndex((prevIndex) =>
        (prevIndex + 1) % slideImg.length
      );
    };

    const intervalId = setInterval(changeImage, 9000); 

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <PrivateLayout>
        <div className="event__wrapper">
          <section className="event__wrapper__header">
            <h2>Event</h2>
            <p>Promotional activities, hurry up to participate</p>
          </section>

          <div className="event__slide__img">
            <img
              key={currentImageIndex}
              src={slideImg[currentImageIndex].img}
              alt="Imag"
            />
          </div>
        </div>
      </PrivateLayout>
    </>
  );
};

export default Event;

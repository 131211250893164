import React from "react";
import { PrivateLayout } from "../../../common";
import HeaderAccount from "./Header.Account";
import AccountAccount from "./Account.Account";
import { NavLink } from "react-router-dom";
import { Icons } from "../../../constant/Icons";
import { account_nav } from "../../../data/account.data";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../services/auth/login.Slice";

const Account = () => {
  const dispatch = useDispatch();
  
  const handleSignOut = () => {
    dispatch(logoutUser());
  };

  return (
    <>
      <PrivateLayout>
        <div className="overflow-y-scroll">
          <HeaderAccount />
          <AccountAccount />

          <div className="account__wrapper">
            <p className="title">Functions</p>
            <span className="desc">
              Common online details functions, so that you can view more
              conveniently
            </span>

            <div className="account__actions">
              {account_nav.map((nav, k) => (
                <NavLink key={k} to={nav.path} onClick={nav.title === "Sign Out" ? handleSignOut : undefined}>
                  <section className="actions">
                    <p>
                      {nav.icon}
                      <span>{nav.title}</span>
                    </p>
                    <Icons.ArrowRight />
                  </section>
                </NavLink>
              ))}
            </div>
          </div>
          
        </div>
      </PrivateLayout>
    </>
  );
};

export default Account;

import { Button, Input, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { paths } from "../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { loginUser } from "../../services/auth/login.Slice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { user,  } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      phone_number: "",
      password: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await dispatch(loginUser(values)).unwrap();
        setIsLoading(false);
        message.success(response?.message);
        navigate(paths.dashboardOverview);
      } catch (err) {
        setIsLoading(false);
        if(err.statusCode === 500 && 502) {
          message.error("Something went wrong, please try again!!!")
        } else {
          message.error(err?.response?.message || err?.message);
        }
         
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (user) {
      navigate(paths.dashboardOverview);
    }
  }, [navigate, user]);

  return (
    <div className="login__wrapper">
      <div className="login__content">
        <div className="login__inner__wrapper">
         <form onSubmit={formik.handleSubmit}>
         <Typography className="top__right">English</Typography>
          <Input 
          placeholder="phone number" 
          className="input__london"
          name="phone_number"
          value={formik.values.phone_number} 
          onChange={formik.handleChange}
          />

          <Input.Password
            placeholder="password"
            className="input__london my-3"
            name="password"
          value={formik.values.password} 
          onChange={formik.handleChange}
          />
          <Typography className="forgot__london">
            <Link to={paths.forgotPassword}>Forgot Password</Link>
          </Typography>
          <Button type="primary" size="large" shape="round" htmlType="submit" loading={isLoading}>
            Login
          </Button>
         </form>

          <Typography className="create__acc">
            Don't have an account yet? <Link to={paths.register}>Sign Up</Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Login;

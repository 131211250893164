import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicRequest } from "../../app/connect";




export const resetUserPassword = createAsyncThunk('/auth/resetPassword', async ({...credential}, { rejectWithValue }) => {
  try {
      const { data } = await publicRequest.post("/auth/reset-password", credential);
      return data;
  } catch (error) {
      return rejectWithValue(error.response.data)
  }
})


const initialState = {
  resetPassword: [],
  loading: false,
  error: null,
  isSuccess: false,
};

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(resetUserPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetUserPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.isSuccess = true;
        state.resetPassword = action.payload;
      })

      .addCase(resetUserPassword.rejected, (state, action) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })

  },
});

export default resetPasswordSlice.reducer;
import React from 'react'
import { AppLayout } from '../../common'
import { SignUpAuth } from '../../components'

const SignUp = () => {
  return (
    <>
        <AppLayout>
            <SignUpAuth />
        </AppLayout>
    </>
  )
}

export default SignUp

export const indexPrefix = ""
export const authPrefix = "/auth";
export const homePrefix = indexPrefix + "/"
export const dashboardPrefix = '/dashboard';



export const paths = {
    home: homePrefix,
    register: authPrefix + '/register',
    forgotPassword: authPrefix + '/forgot-password',
    dashboardOverview: dashboardPrefix  + '/user/welcome',
    dashboardEvent: dashboardPrefix + '/user/event',
    dashboardBooking: dashboardPrefix + '/user/booking',
    dashboardRecord: dashboardPrefix + '/user/record',
    dashboardAccount: dashboardPrefix + '/user/account',
    dashboardMembership: dashboardPrefix + '/user/account/membership',
    dashboardBindWallet: dashboardPrefix + '/user/account/bind/wallet',
    dashboardAccountDetails: dashboardPrefix + '/user/account/details/records',
    notMatch: indexPrefix + '*',
}
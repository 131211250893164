import { message } from "antd";


// a custom function to format date, time and year
export const formatDateAndTime = (created_at) => {
    const dateObj = new Date(created_at);
    const timeString = dateObj.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    const dateString = dateObj.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
  
    return `${timeString} ${dateString}`;
  };


  export const handleCopyClick = (copyId) => {
    navigator.clipboard.writeText(copyId);
    message.success("Copied Successfully");
  };
import React, { useEffect, useState } from "react";
import { MessageModal, PrivateLayout } from "../../../common";
import { Images } from "../../../constant/Images";
import { Button, Modal, Popconfirm, Skeleton, Typography } from "antd";
import { bookingSummary, bookingTaskDesc } from "../../../data/data";
import DoTask from "./do_task";
import { useDispatch, useSelector } from "react-redux";
import { UserDoTask } from "../../../services/application/do_task.Slice";
import { fetchUserInfo } from "../../../services/application/user_info.Slice";
import DepositAccount from "../account/Deposit.Account";
import { toast } from "react-toastify";


const Booking = () => {
  const [ doTask, setDoTask ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const { info, loading } = useSelector((state) => state.info);
  const { task } = useSelector((state) => state.task);
  const [ isError, setIsError ] = useState(false);
  const [ isDeposit, setIsDeposit ] = useState(false);
  const dispatch = useDispatch();

  const handleDeposit = () => {
    setIsDeposit(!isDeposit);
    setIsError(false);
  };

  const handleTopUp = () => {
    setIsError(true);
    setDoTask(false)
  }

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  const handleTask = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(UserDoTask()).unwrap();
      setTimeout(() => {
        setIsLoading(false);
        setDoTask(true, response.message);
      }, 2000);
      dispatch(fetchUserInfo());
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.message || err?.response?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const userInfo = info?.data;
  const bookingRecord = bookingSummary(userInfo);

  return (
    <>
      <PrivateLayout>
        <div className="booking__video__wrapper">
          <img src={Images.VideoGIF} alt="" />

          <div className="booking__container">
            <Popconfirm
              title="You're about to Book ticket "
              onConfirm={() => handleTask()}
              loading={isLoading}
              disabled={isLoading}
              okText="Buy ticket"
              cancelText="Cancel"
            >
              <Button type="primary" size="large">
                START
              </Button>
            </Popconfirm>
            <div className="flex justify-between items-center">
              <Typography className="bonus">
                Trial Bonus:{" "}
                <Skeleton
                  loading={loading}
                  active={true}
                  paragraph={{ rows: 0 }}
                >
                  <span>$ {userInfo?.wallet[2]?.wallet_balance ?? 0}</span>
                </Skeleton>
              </Typography>

              {userInfo?.strike === true ? (
                <Typography className="bonus text-red-500">
                  <Skeleton
                    loading={loading}
                    active={true}
                    paragraph={{ rows: 0 }}
                  >
                    {" "}
                    <span className="bonus__red">
                      {task?.data?.delux === null ? null : `Delux: $${task?.data?.delux?.delux_amount ?? 0}` }
                     
                    </span>
                  </Skeleton>
                </Typography>
              ) : null}
            </div>

            <div className="booking__summary">
              {bookingRecord.map((book, k) => (
                <section className="booking__summary__content" key={k}>
                  <Typography>{book.title}</Typography>
                  <Skeleton
                    loading={loading}
                    active={true}
                    paragraph={{ rows: 0 }}
                  >
                    <Typography>{book.amount}</Typography>
                  </Skeleton>
                </section>
              ))}
            </div>

            <div className="booking__description">
              <Typography>Task Description</Typography>
              <Typography>
                {bookingTaskDesc.map((task, k) => (
                  <ul key={k}>
                    <li>{task.desc}</li>
                  </ul>
                ))}
              </Typography>
            </div>
          </div>
        </div>

        {doTask && (
          <Modal
            open={doTask}
            closable={false}
            footer={null}
            width={380}
            centered
          >
            <DoTask close={() => handleTopUp()} mgs={task} okClose={() => setDoTask(false)} />
          </Modal>
        )}

        {isError && (
          <MessageModal
            open={isError}
            width={380}
            closable={false}
            cancel={() => setIsError(false)}
            btnText="Submit receipt"
            onConfirm={() => handleDeposit()}
          />
        )}

        {isDeposit && (
          <Modal
            title="Deposit"
            open={isDeposit}
            onCancel={handleDeposit}
            footer={null}
            closeIcon="X"
            width="350px"
          >
            <DepositAccount close={handleDeposit} />
          </Modal>
        )}
      </PrivateLayout>
    </>
  );
};

export default Booking;

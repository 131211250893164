import React from "react";
import { nav_list } from "../../data/data";
import { Link } from "react-router-dom";
import { Typography } from "antd";

const Navbar = () => {
  return (
    <footer className="nav__bottom">
      {nav_list.map((item, index) => (
        <Link key={index} to={item?.path}>
          <Typography
            className={`nav__icon ${
              window.location.pathname === item?.path ? "active" : "inherit"
            }`}
          >
            {item?.icon} {item?.title}
          </Typography>
        </Link>
      ))}
    </footer>
  );
};

export default Navbar;

import React, { useState } from 'react'
import { CustomTab, PrivateLayout } from '../../../common'
import { Icons } from '../../../constant/Icons'
import { useNavigate } from 'react-router-dom';
import { withdraw_level } from '../../../data/account.data';
import { paths } from '../../../routes/routes';
import { BankBind } from './Bank.Bind';

const BidWalletAccount = () => {
    const navigate = useNavigate();
    const [selectedLevel, setSelectedLevel] = useState("bank");

    const handleArrowClick = () => {
        navigate(paths.dashboardAccount);
    };

    return (
        <PrivateLayout>
            <>
                <div className='account__level'>
                    <section className='account__level__nav'>
                        <Icons.ArrowLeft onClick={handleArrowClick} />
                        <span>Withdrawal Information</span>
                        <span></span>
                    </section>

                    <section className='account__level__tab'>
                        <CustomTab
                            tabs={withdraw_level}
                            selectedTab={selectedLevel}
                            onTabClick={setSelectedLevel}
                        />
                    </section>

                    {selectedLevel === "bank" && (
                       <>
                         <BankBind />
                       </>
                    )}

                    {selectedLevel === "crypto" && (
                        <div className='login__inner__withdraw'>No Data</div>
                    )}

                </div>
            </>
        </PrivateLayout>

    )
}

export default BidWalletAccount
import React from 'react'
import { DashboardBindWallet } from '../../components'

const AccountBind = () => {
  return (
    <div>
        <DashboardBindWallet />
    </div>
  )
}

export default AccountBind
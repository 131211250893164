import React, { useEffect, useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { app } from "../config/app";
import { logoutUser } from "../services/auth/login.Slice";


export const ProtectedRoute = ({ user, redirect = app.before_auth_path }) => {
  const dispatch = useDispatch();
  const userAccessToken = user?.token;

  const currentDate = useMemo(() => new Date(), []);

  useEffect(() => {
    if (userAccessToken) {
      const decod_access = jwtDecode(userAccessToken);
      if (decod_access.exp * 1000 < currentDate.getTime()) {
        dispatch(logoutUser());
      }
    }
  }, [userAccessToken, dispatch, currentDate]);


  if (!userAccessToken) {
    return <Navigate to={redirect} replace />;
  }

  return <Outlet />;
};


import React, { useState } from "react";
import { CustomTab, PrivateLayout } from "../../../common";
import { Typography } from "antd";
import { tab_list } from "../../../data/data";
import AllRecords from "./All.Records";
import ProcessingRecords from "./Processing.Records";
import CompletedRecords from "./Completed.Records";

const Record = () => {
  const [selectedRecord, setSelectedRecord] = useState("all");
  const [loading] = useState(false);

  return (
    <>
      <PrivateLayout>
     <>
     <div className="h-[100vh]">
          <Typography className="record__title">Task Record</Typography>

          <CustomTab
            tabs={tab_list}
            selectedTab={selectedRecord}
            onTabClick={setSelectedRecord}
          />

          {selectedRecord === "all" && (
            <div id="overflow">
              <AllRecords />
            </div>
          )}

          {selectedRecord === "processing" && (
            <>{loading && "loading" ? <ProcessingRecords /> : "No Data"}</>
          )}

          {selectedRecord === "completed" && (
            <>{loading && "loading" ? <CompletedRecords /> : "No Data"}</>
          )}
        </div>
     </>
      </PrivateLayout>
    </>
  );
};

export default Record;

import { paths } from "../routes/routes";


export const app = {
    apiBaseUrl: process.env.REACT_APP_API_URL,
    before_auth_path: paths.home,
    after_auth_path: paths.dashboardOverview,
    company: {
        name: 'IM London',
        url: 'imlondon.org',
        full_url: 'https://imlondon.org',
    }
}
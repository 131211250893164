import React, { useEffect } from 'react'
import { Images } from '../../../constant/Images'
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserInfo } from "../../../services/application/user_info.Slice";

const HeaderAccount = () => {
   const dispatch = useDispatch();
   const { info } = useSelector(state => state.info)

useEffect(() => {
   dispatch(fetchUserInfo())
},[dispatch])

const userInfo = info?.data;

  return (
    <div className='account__header'>
                <section className='account__img'>
                   <img src={Images.Splash1} alt='' />
                   <div className='account__id__wrapper'>
                   <section className='account__id'>
                      <span>{userInfo?.phone_number}</span>
                      <span>UID: <b>{userInfo?.uuid}</b></span>
                   </section>
                   <span>Invitation Code: <b>{userInfo?.refferal_code}</b> </span>
                   </div>
                </section>
            </div>
  )
}

export default HeaderAccount
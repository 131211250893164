


import { configureStore } from '@reduxjs/toolkit';
import registerReducer from '../services/auth/register.Slice';
import authReducer from '../services/auth/login.Slice';
import capchaReducer from '../services/auth/capcha.Slice';
import resetPasswordReducer from "../services/auth/reset_password.Slice";
import taskReducer from "../services/application/do_task.Slice";
import withdrawReducer from "../services/application/withdraw.Slice";
import transactionsReducer from "../services/application/transactions.Slice";
import infoReducer from "../services/application/user_info.Slice";
import fundReducer from "../services/application/deposit.Slice";
import walletAddressReducer from "../services/application/fund_address.Slice";

export const store = configureStore({
    reducer: {
        register: registerReducer,
        auth: authReducer,
        capcha: capchaReducer,
        resetPassword: resetPasswordReducer,
        task: taskReducer,
        withdraw: withdrawReducer,
        transactions: transactionsReducer,
        info: infoReducer,
        fund: fundReducer,
        walletAddress: walletAddressReducer
    }
});



import loginBg from "../assets/img/login_bg.png";
import Traveller from "../assets/img/traveller.jpg";
import Splash1 from "../assets/img/splash.jpg";
import Splash2 from "../assets/img/img1.jpg";
import VideoGIF from "../assets/video/video.gif";
import Level0 from "../assets/levels/level_0.png";
import Level1 from "../assets/levels/level_1.png";
import Location from "../assets/img/location.jpg"



export const Images = {
    loginBg,
    Traveller,
    Splash1,
    Splash2,
    VideoGIF,
    Level0,
    Level1,
    Location
}
import { Button, Input, Spin } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { BindBankWallet } from "../../../services/application/bind_bank.Slice";
import { updateUserDetails } from "../../../services/auth/login.Slice";
import { toast } from "react-toastify";

export const BankBind = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleBindBank = async (values) => {
    setLoading(true);
    try {
      const response = await dispatch(BindBankWallet({ ...values })).unwrap();
      setLoading(false);
      toast.success(response.message);
      dispatch(updateUserDetails(response?.data));
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      bank_name: "",
      account_number: "",
      account_name: "",
      usdt_trc20: "",
      usdt_erc20: ""
    },
    onSubmit: handleBindBank,
    validate: (values) => {
      const errors = {};
      if (!values.bank_name) {
        errors.bank_name = "Bank Name is required";
      }
      if (!values.account_number) {
        errors.account_number = "Account Number is required";
      }
      return errors;
    },
    validateOnMount: true,
  });


  return (
    <>
      <div className="login__inner__withdraw">
        <form onSubmit={formik.handleSubmit}>

          <Input
            type="text"
            size="large"
            placeholder="Account Name"
            className="input__londons mb-2"
            prefix={<div className="resend__code">Account Name</div>}
            name="account_name"
            value={formik.values.account_name}
            onChange={formik.handleChange} />

          <Input
            type="text"
            size="large"
            placeholder="Account Number"
            className="input__londons mb-2"
            prefix={<div className="resend__code">Account Number</div>}
            name="account_number"
            value={formik.values.account_number}
            onChange={formik.handleChange} />

          <Input
            type="text"
            size="large"
            placeholder="Bank Name"
            className="input__londons mb-2"
            prefix={<div className="resend__code">Bank Name</div>}
            name="bank_name"
            value={formik.values.bank_name}
            onChange={formik.handleChange} />

          <Input
            type="text"
            size="large"
            placeholder="USDT TRC"
            className="input__londons mb-2"
            prefix={<div className="resend__code">USDT TRC</div>}
            name="usdt_trc20"
            value={formik.values.usdt_trc20}
            onChange={formik.handleChange} />

            <Input
            type="tel"
            size="large"
            placeholder="usdt erc"
            className="input__londons mb-2"
            prefix={<div className="resend__code">USDT ERC</div>}
            name="usdt_erc20" 
            value={formik.values.usdt_erc20}
            onChange={formik.handleChange} />
            
            


          <Button type="primary" size="large" shape="round" htmlType="submit" disabled={!formik.isValid || loading}>
            {loading ? <Spin /> : "Confirm Bank Binding"}
          </Button>
        </form>
      </div>

      <div className="login__inner__withdraw__content">
        <p>Please confirm the binding information. If the binding has been confirmed, no modifications can be made afterwards.</p>
        <p> If you need to modify your bank account information under special circumstances, please contactour customer service for modification.  </p>
        <p> When modifying bank account information, our customer service will verify your mobile phone number, bank card number and identification with you.  </p>
      </div>
    </>
  );
};

import React, { useState } from 'react'
import { CustomTab, PrivateLayout } from '../../../common'
import { Icons } from '../../../constant/Icons'
import { useNavigate } from 'react-router-dom';
import { tab_level } from '../../../data/account.data';
import CurrentLevelAccount from './Current.Level.Account';
import DescriptLevelAccount from "./Descript.Level.Account"
import { paths } from '../../../routes/routes';

const LevelAccount = () => {
    const navigate = useNavigate();
    const [selectedLevel, setSelectedLevel] = useState("current");

    const handleArrowClick = () => {
        navigate(paths.dashboardAccount);
    };

    return (
        <PrivateLayout>
            <>
                <div className='account__level'>
                    <section className='account__level__nav'>
                        <Icons.ArrowLeft onClick={handleArrowClick} />
                        <span>LEVEL</span>
                        <span></span>
                    </section>

                    <section className='account__level__tab'>
                        <CustomTab
                            tabs={tab_level}
                            selectedTab={selectedLevel}
                            onTabClick={setSelectedLevel}
                        />
                    </section>

                    {selectedLevel === "current" && (
                        <CurrentLevelAccount selectedLevel={selectedLevel} />
                    )}

                    {selectedLevel === "description" && (
                        <DescriptLevelAccount />
                    )}

                </div>
            </>
        </PrivateLayout>

    )
}

export default LevelAccount


import { GoHomeFill as HomeIcon } from "react-icons/go";
import { AiFillStar as EventIcon, AiOutlineFileProtect as RecordIcon, AiOutlineArrowRight as ArrowRight, AiOutlineArrowLeft as ArrowLeft } from "react-icons/ai";
import { BsAirplaneFill as BookingIcon } from "react-icons/bs";
import { FaRegUserCircle as UserIcon, FaUpload as UploadIcon, FaReceipt as ReceiptIcon } from "react-icons/fa";
import { GrAnnounce as SpeakerIcon, GrTransaction as TransitIcon } from "react-icons/gr";
import { HiOutlineInformationCircle as AcctAbout } from "react-icons/hi";
import { FiSettings as AcctSettings } from "react-icons/fi";
import { BiSupport as AcctCustomer, BiHelpCircle as AcctHelp, BiWallet as AcctWallet, BiLogOutCircle as AcctLogout } from "react-icons/bi";
import { TbMessageLanguage as AcctLang } from "react-icons/tb";
import { MdOutlineFeedback as AcctFeedback, MdCopyAll as CopyIcon } from "react-icons/md";
import { SiOpslevel as AcctLevel } from "react-icons/si";


export const Icons = {
    HomeIcon,
    EventIcon,
    BookingIcon,
    RecordIcon,
    UserIcon,
    SpeakerIcon,
    TransitIcon,
    ArrowRight,
    AcctAbout,
    AcctSettings,
    AcctCustomer,
    AcctLang,
    AcctFeedback,
    AcctHelp,
    AcctWallet,
    AcctLogout,
    AcctLevel,
    ArrowLeft,
    CopyIcon,
    UploadIcon,
    ReceiptIcon
}
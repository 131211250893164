import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { withdraw_summary } from "../../../data/account.data";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { WithdrawCredit } from "../../../services/application/withdraw.Slice";
import { fetchUserInfo } from "../../../services/application/user_info.Slice";
import { toast } from "react-toastify";

const WithdrawAccount = ({ close }) => {
  const dispatch = useDispatch();
  const { info } = useSelector((state) => state.info);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  const userInfo = info?.data;

  const handleWithdraw = async (values) => {
    setLoading(true);
    try {
      const response = await dispatch(WithdrawCredit({ ...values })).unwrap();
      setLoading(false);
      toast.success(response.message);
      close();
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      wallet: "",
    },
    onSubmit: handleWithdraw,
    validateOnMount: true,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="account__deposit">
        <section className="account__balance">
          <span className="-mb-3">Collection Address</span>
        </section>
        <section className="account__select">
          <select
            name="wallet"
            value={formik.values.wallet}
            onChange={formik.handleChange}
          >
            <option defaultValue>select payment account</option>
            <option value="bank">Bank</option>
            <option value="erc20">USDT ERC20</option>
            <option value="tr20">USDT TRC20</option>
          </select>
        </section>

        <section className="account__select">
          <span className="desc">Amount</span>
          <Input
            size="large"
            placeholder="amount"
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
          />
        </section>

        <section className="account__withdraw">
          <span className="desc">Balance:</span>
          <span className="amt">{userInfo?.sub_balance?.holding_balance}</span>
        </section>

        <section className="account__withdraw__wrapper">
          {withdraw_summary.map((sum, k) => (
            <div className="account__withdraw__summary" key={k}>
              <span>{sum.label}</span>
              <span>{sum.value}</span>
            </div>
          ))}
        </section>

        <Button
          size="large"
          type="primary"
          htmlType="submit"
          disabled={!formik.isValid || loading}
          loading={loading}
        >
          Withdraw Now
        </Button>
      </div>
    </form>
  );
};

export default WithdrawAccount;

import React from 'react'
import { DashboardHome } from '../../components'

const Home = () => {
  return (
    <div>
        <DashboardHome />
    </div>
  )
}

export default Home
import React from 'react'
import { AppLayout } from '../../common'
import { ForgotPasswordAuth,  } from '../../components'

const Forgot = () => {
  return (
    <>
        <AppLayout>
            <ForgotPasswordAuth />
        </AppLayout>
    </>
  )
}

export default Forgot
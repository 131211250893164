
import axios from 'axios';
import { app } from '../config/app';

export const publicRequest = axios.create({
  baseURL: app.apiBaseUrl,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

export const userRequest = axios.create({
  baseURL: app.apiBaseUrl,
});

export const setAuthToken = (TOKEN) => {
  userRequest.defaults.headers.Authorization = `Bearer ${TOKEN}`;
};

const TOKEN = JSON.parse(localStorage.getItem("user"))?.token;

if (TOKEN) {
  setAuthToken(TOKEN);
}


import React from 'react';
import { Navbar } from "../../components"

const PrivateLayout = (props) => {
  return (
    <div className='app__layout'>
        <div className='login__wrapper'>
          <div className='main__container'>
            {props.children}
              <Navbar />
          </div>
        </div>
    </div>
  )
}

export default PrivateLayout
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../app/connect";



export const fetchWalletAddress = createAsyncThunk('/auth/user/walletAddress', async (_, { rejectWithValue }) => {
  try {
      const { data } = await userRequest.get("/user/fetch-crypto",);
      return data;
  } catch (error) {
      return rejectWithValue(error.response.data)
  }
})


const initialState = {
  walletAddress: [],
  loading: false,
  error: null,
  isSuccess: false,
};

const walletAddressSlice = createSlice({
  name: "walletAddress",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchWalletAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWalletAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.isSuccess = true;
        state.walletAddress = action.payload;
      })

      .addCase(fetchWalletAddress.rejected, (state, action) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })

  },
});

export default  walletAddressSlice.reducer;
import React, { useEffect } from "react";
import { Images } from "../../../constant/Images";
import { fetchUserInfo } from "../../../services/application/user_info.Slice";
import { useDispatch, useSelector } from "react-redux";

const CurrentLevelAccount = ({ selectedLevel }) => {
  const dispatch = useDispatch()
  const { info } = useSelector(state => state.info)


  useEffect(() => {
    dispatch(fetchUserInfo())
  },[dispatch])


  const userInfo = info?.data;


  return (
    <div className="account__current__level">
      <img src={Images.Level0} alt={selectedLevel} />

      <section className="ranking">
        <p>
          <span>V</span> <span>{userInfo?.level ?? "N/A"}</span>
        </p>

        <article>Current VIP {userInfo?.level} away from upgrading to VIP 1 0/10000</article>
      </section>
    </div>
  );
};

export default CurrentLevelAccount;

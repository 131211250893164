import React, { useState } from "react";
import { CustomTab, PrivateLayout } from "../../../common";
import { Icons } from "../../../constant/Icons";
import { useNavigate } from "react-router-dom";
import { account_details_record } from "../../../data/account.data";
import { paths } from "../../../routes/routes";
import WalletTransactions from "./Wallet.Transactions";
import CommissionTransactions from "./Commission.Transaction";
import WithdrawTransactions from "./Withdraw.Transaction";
import RecordTransactions from "./Record.Transactions";

const DetailsAccountRecord = () => {
  const navigate = useNavigate();
  const [selectedLevel, setSelectedLevel] = useState("wallet");

  const handleArrowClick = () => {
    navigate(paths.dashboardAccount);
  };

  return (
    <PrivateLayout>
      <>
        <div className="account__level">
          <section className="account__level__nav">
            <Icons.ArrowLeft onClick={handleArrowClick} />
            <span>Account Details</span>
            <span></span>
          </section>

          <section className="account__level__tab">
            <CustomTab
              tabs={account_details_record}
              selectedTab={selectedLevel}
              onTabClick={setSelectedLevel}
            />
          </section>

          {selectedLevel === "wallet" && (
            <>
              <WalletTransactions />
            </>
          )}

          {selectedLevel === "commission" && <CommissionTransactions />}

          {selectedLevel === "withdraw" && <WithdrawTransactions />}

          {selectedLevel === "record" && <RecordTransactions />}
        </div>
      </>
    </PrivateLayout>
  );
};

export default DetailsAccountRecord;

import React from "react";

const CustomTab = ({ tabs, selectedTab, onTabClick }) => {
  return (
    <div className="record__wrapper">
      {tabs.map((tab, index) => (
        <div
          key={index}
          className={`record__tab ${
            selectedTab === tab.value ? "selected__tab" : ""
          }`}
          value={tab.value}
          onClick={() => onTabClick(tab.value)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

export default CustomTab;

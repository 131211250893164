import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../app/connect";




export const fetchTransactions = createAsyncThunk('/auth/user/transactions', async ({type}, { rejectWithValue }) => {
  try {
      const { data } = await userRequest.get(`/user/fetch-transaction?type=${type}`);
      return data;
  } catch (error) {
      return rejectWithValue(error.response.data)
  }
})



const initialState = {
  transactions: [],
  loading: false,
  error: null,
  isSuccess: false,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.isSuccess = true;
        state.transactions = action.payload;
      })

      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })

  },
});

export default transactionsSlice.reducer;
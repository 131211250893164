import React from 'react'
import { DashboardEvent } from '../../components'

const Event = () => {
  return (
    <div>
        <DashboardEvent />
    </div>
  )
}

export default Event
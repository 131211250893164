import React from 'react'
import { DashboardBooking } from '../../components'

const Booking = () => {
  return (
    <div>
        <DashboardBooking />
    </div>
  )
}

export default Booking
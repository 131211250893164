import React from "react";
import { Icons } from "../constant/Icons";
import { Images } from "../constant/Images";
import { paths } from "../routes/routes";

export const account_nav = [
    {
        icon: <Icons.AcctLevel />,
        title: "Level",
        path: "/dashboard/user/account/membership"
    },

    {
        icon: <Icons.UserIcon />,
        title: "Details",
        path: paths.dashboardAccountDetails
    },

    {
        icon: <Icons.AcctAbout />,
        title: "About Us",
        path: "/dashboard/user/account/about"
    },

    {
        icon: <Icons.AcctSettings />,
        title: "Settings",
        path: "/dashboard/user/account/settings"
    },

    {
        icon: <Icons.AcctCustomer />,
        title: "Customer Services",
        path: "/dashboard/user/account/services"
    },

    {
        icon: <Icons.AcctLang />,
        title: "Language Settings",
        path: "/dashboard/user/account/language"
    },

    {
        icon: <Icons.AcctFeedback />,
        title: "Feedback",
        path: "/dashboard/user/account/feedback"
    },

    {
        icon: <Icons.AcctHelp />,
        title: "Help Center",
        path: "/dashboard/user/account/help"
    },

    {
        icon: <Icons.AcctWallet />,
        title: "Bind Wallet Address",
        path: paths.dashboardBindWallet
    },

    {
        icon: <Icons.AcctLogout />,
        title: "Sign Out",
        path: paths.home
    },
]


export const select_amt = [
    {
        amt: "100"
    },

    {
        amt: "150"
    },

    {
        amt: "200"
    },

    {
        amt: "300"
    },

    {
        amt: "500"
    },

    {
        amt: "1000"
    },
]


export const withdraw_summary = [
    {
        label: "Service Charge",
        value: "0.00"
    },

    {
        label: "Actual Amount Received",
        value: "0.00"
    },


    {
        label: "Minimum Withdrawal",
        value: "10.00"
    }
]


export const tab_level = [
    {
        label: "Current Level",
        value: "current"
    },

    {
        label: "Level Description",
        value: "description"
    },
]


export const withdraw_level = [
    {
        label: "Bank",
        value: "bank"
    },

    {
        label: "Crypto Wallet",
        value: "crypto"
    },
]


export const account_details_record = [
    {
        label: "Wallet",
        value: "wallet"
    },

    {
        label: "Commission",
        value: "commission"
    },

    {
        label: "Withdraw",
        value: "withdraw"
    },

    {
        label: "Record",
        value: "record"
    },
]


export const account_level_desc = [
    {
        level: "VIP 0",
        img: Images.Level0
    },

    {
        level: "VIP 1",
        img: Images.Level1
    },

    {
        level: "VIP 2",
        img: Images.Level0
    },

    {
        level: "VIP 3",
        img: Images.Level1
    },

    {
        level: "VIP 4",
        img: Images.Level1
    },


    {
        level: "VIP 5",
        img: Images.Level1
    },

    {
        level: "VIP 6",
        img: Images.Level0
    },

    {
        level: "VIP 7",
        img: Images.Level1
    },

    {
        level: "VIP 8",
        img: Images.Level1
    },
]



export const options = [
    {
      label: "USDT",
      value: "usdt",
    },

    {
      label: "FIAT",
      value: "fiat",
    },
  ];

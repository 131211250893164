import React from 'react'
import { DashboardDetailsAccount } from '../../components'

const AccountDetails = () => {
  return (
    <div>
        <DashboardDetailsAccount />
    </div>
  )
}

export default AccountDetails
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { publicRequest } from "../../app/connect";




export const registerUser = createAsyncThunk('/auth/register', async ({sessionId, ...credential}, { rejectWithValue }) => {
  try {
      const { data } = await publicRequest.put("/auth/register", credential, {
        headers: {
            session_id: sessionId
        }
      });
      return data;
  } catch (error) {
      return rejectWithValue(error.response.data)
  }
})


const initialState = {
  register: [],
  loading: false,
  error: null,
  isSuccess: false,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.isSuccess = true;
        state.register = action.payload;
      })

      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })

  },
});

export default registerSlice.reducer;
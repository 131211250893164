import React from 'react'

const TransactionHistory = (props) => {
  return (
         <section className='transaction__history' key={props.key}>
            <div className='history__left'>
                <span>{props.title}</span>
                <span>{props.desc}</span>
            </div>

            <div className='history__right'>
                <span>{props.amount}</span>
                <span>{props.date}</span>
                <span>{props.rate}</span>
            </div>
        </section>
  )
}

export default TransactionHistory
import { Button, Input, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchCapcha } from "../../services/auth/capcha.Slice";
import { paths } from "../../routes/routes";
import { useFormik } from "formik";
import { registerUser } from "../../services/auth/register.Slice";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { capcha } = useSelector((state) => state.capcha);

  useEffect(() => {
    dispatch(fetchCapcha());
  }, [dispatch]);

  const sessionID = capcha?.data;

  const formik = useFormik({
    initialValues: {
      phone_number: "",
      recapcha: "",
      password: "",
      full_name: "",
    },

    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const response = await dispatch(
          registerUser({ sessionId: sessionID?.session_id, ...values })
        ).unwrap();
        setIsLoading(false);
        message.success(response?.message);
        navigate(paths.home);
      } catch (err) {
        setIsLoading(false);
        message.error(err?.message);
      } finally {
        setIsLoading(false);
      }
    },
  });


  return (
    <div className="login__wrapper">
      <div className="login__content">
        <div className="login__inner__wrapper">
        <form onSubmit={formik.handleSubmit}>
          <Typography className="top__right">English</Typography>

          <Input 
            placeholder="Full Name" 
            className="input__londons mb-3"
            name="full_name"
            value={formik.values.full_name}
            onChange={formik.handleChange} 
            />

            <Input 
            placeholder="Phone Number" 
            className="input__londons"
            name="phone_number"
            value={formik.values.phone_number}
            onChange={formik.handleChange} 
            />

            <Input.Password
              placeholder="password"
              className="input__londons my-3"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange} 
            />

            <Input
              placeholder="Recapcha Code"
              className="input__londons mb-3"
              name="recapcha"
              value={formik.values.recapcha}
              onChange={formik.handleChange}
              addonAfter={sessionID?.capcha}
            />

            <Button type="primary" size="large" shape="round" htmlType="submit" loading={isLoading}>
              Sign Up
            </Button>
          </form>
          <Typography className="create__acc">
            Already have an account? <Link to={paths.home}>Login</Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

import React, { useEffect, useState } from "react";
import { Icons } from "../../../constant/Icons";
import { Modal, Popconfirm } from "antd";
import DepositAccount from "./Deposit.Account";
import WithdrawAccount from "./Withdraw.Account";
import { handleCopyClick } from "../../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletAddress } from "../../../services/application/fund_address.Slice";


const AccountAccount = () => {
  const dispatch = useDispatch();
  const [isDeposit, setIsDeposit] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);
  const { walletAddress } = useSelector(state => state.walletAddress)

  useEffect(() => {
    dispatch(fetchWalletAddress());
  }, [dispatch])

  const fundAddress = walletAddress?.data;

  const handleDeposit = () => {
    setIsDeposit(!isDeposit);
  };

  const handleWithdraw = () => {
    setIsWithdraw(!isWithdraw);
  };

  return (
    <>
      <div className="account__account">
        <Popconfirm
          title="Pay to any of this addresses"
          description={<div className="deposit__copy"> 
          {fundAddress && fundAddress?.map((item) => (
            <div key={item?.id} className="deposit__copy__content">
                <p>{item?.name.slice(0, 5)}</p>
                <p><span>{item && item?.walletAddress.slice(0, 30)}</span>  <Icons.CopyIcon size={25} onClick={() => handleCopyClick(item && item?.walletAddress)} /> </p>
            </div>
          ))}
          </div>
          }
          onConfirm={() => handleDeposit()}
          okText="Submit receipt"
          cancelText="Cancel"
          placement="topLeft"

        >
          <span >
            <Icons.UserIcon /> Deposit
          </span>
        </Popconfirm>

        <span onClick={handleWithdraw}>
          <Icons.UserIcon /> Withdrawal
        </span>
      </div>

      {isDeposit && (
        <Modal
          title="Deposit"
          open={isDeposit}
          onCancel={handleDeposit}
          footer={null}
          closeIcon="X"
          width="350px"
        >
          <DepositAccount close={handleDeposit} />
        </Modal>
      )}

      {isWithdraw && (
        <Modal
          title="Withdraw"
          open={isWithdraw}
          onCancel={handleWithdraw}
          footer={null}
          closeIcon="X"
          width="350px"
        >
          <WithdrawAccount close={handleWithdraw} />
        </Modal>
      )}
    </>
  );
};

export default AccountAccount;

import { Button, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletAddress } from "../services/application/fund_address.Slice";
import { Icons } from "../constant/Icons";
import { handleCopyClick } from "../utils/Utils";

const ErrorModal = (props) => {
  const dispatch = useDispatch();
  const { walletAddress } = useSelector((state) => state.walletAddress);

  useEffect(() => {
    dispatch(fetchWalletAddress());
  }, [dispatch]);

  const fundAddress = walletAddress?.data;

  return (
    <Modal
      title={props.title}
      open={props.open}
      width={380}
      closable={false}
      footer={null}
      centered
      onCancel={props.cancel}
    >
      <center id="do__task">
        <p className="pay__to">Pay to any of this addresses</p>

        <div className="deposit__copy">
          {fundAddress &&
            fundAddress?.map((item) => (
              <div key={item?.id} className="deposit__copy__content">
                <p>{item?.name.slice(0, 5)}</p>
                <p>
                  <span>{item && item?.walletAddress.slice(0, 30)}</span>{" "}
                  <Icons.CopyIcon
                    size={25}
                    onClick={() => handleCopyClick(item && item?.walletAddress)}
                  />{" "}
                </p>
              </div>
            ))}
        </div>

        <p className="btn__payment__desc mt-4">Please click on the button below after payment</p>
        <Button
          type="primary"
          size="large"
          onClick={props.onConfirm}
          disabled={props.disabled}
        >
          {props.btnText}
        </Button>
      </center>
    </Modal>
  );
};

export default ErrorModal;

import React from 'react'
import { account_level_desc } from '../../../data/account.data'

const DescriptLevelAccount = () => {
  return (
    <div className='account__desc__level'>
      {account_level_desc.map((desc, k) => (
        <section className='account__level__description' key={k}>
          <p>{desc.level}</p>
          <p>$<span>0</span> - $<span>9999</span> </p>

          <img src={desc.img} alt="level" />

          <p>Commission Rate: 1.0%</p>
          <p>Daily Rating: 30</p>

        </section>
      ))}

    </div>
  )
}

export default DescriptLevelAccount
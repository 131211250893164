import React, { useEffect, useState } from "react";
import { Pagination, TransactionHistory } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../../services/application/transactions.Slice";
import { formatDateAndTime } from "../../../utils/Utils";

const RecordTransactions = () => {
  const dispatch = useDispatch();
  const { transactions } = useSelector((state) => state.transactions);
  const [itemOffset, setItemOffset] = useState(1);
  const [ itemsPerPage ] = useState(5);

  useEffect(() => {
    dispatch(fetchTransactions({ type: "task" }));
  }, [dispatch]);

  const walletTran = transactions?.data?.data || [];


  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(walletTran.length / itemsPerPage);
  const currentItems = walletTran.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % walletTran.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="login__inner__withdraw">
      {Array.isArray(currentItems) &&
        currentItems.map((item) => (
          <TransactionHistory
            key={item.id}
            title={item?.narration}
            desc="Balance before charge"
            amount={item.amount}
            date={formatDateAndTime(item.createdAt)}
            rate={item.post_balance}
          />
        ))}

<Pagination handlePageClick={handlePageClick} pageCount={pageCount} />
    </div>
  );
};

export default RecordTransactions;

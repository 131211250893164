import React from 'react'
import { DashboardRecord } from '../../components'

const Record = () => {
  return (
    <div>
        <DashboardRecord />
    </div>
  )
}

export default Record
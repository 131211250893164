import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../app/connect";




export const BindBankWallet = createAsyncThunk('/auth/user/bindBank', async ({...credential}, { rejectWithValue }) => {
  try {
      const { data } = await userRequest.patch("/auth/update-bank-information", credential);
      return data;
  } catch (error) {
      return rejectWithValue(error.response.data)
  }
})


const initialState = {
  bindBank: [],
  loading: false,
  error: null,
  isSuccess: false,
};

const bindBankSlice = createSlice({
  name: "bindBank",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(BindBankWallet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(BindBankWallet.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.isSuccess = true;
        state.bindBank = action.payload;
      })

      .addCase(BindBankWallet.rejected, (state, action) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })

  },
});

export default bindBankSlice.reducer;
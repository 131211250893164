import React from 'react'
import { AppLayout } from '../../common'
import { LoginAuth } from '../../components'

const Login = () => {
  return (
    <>
        <AppLayout>
            <LoginAuth />
        </AppLayout>
    </>
  )
}

export default Login
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userRequest } from "../../app/connect";




export const fetchUserInfo = createAsyncThunk('/auth/user/info', async (_, { rejectWithValue }) => {
  try {
      const { data } = await userRequest.get("/user/fetch-info",);
      return data;
  } catch (error) {
      return rejectWithValue(error.response.data)
  }
})


const initialState = {
  info: [],
  loading: false,
  error: null,
  isSuccess: false,
};

const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.isSuccess = true;
        state.info = action.payload;
      })

      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = action.payload;
      })

  },
});

export default infoSlice.reducer;